<template>
  <div class="seat-result">
    {{ name }} {{ seat }}
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    seat: {
      type: String,
      default: '',
    },
  },
};
</script>
